.tooltip-container {
    text-align: center;
    position: relative;
    padding: 10px;
}

.tooltipBtn {
    background-color: #0068B9;
    color: white;
    font-size: 18px;
    padding: 7px 7px;
    border: none;
    width: 250px;
}

.tooltip {
    font-size: 18px;
    position: absolute;
    background-color: black;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
    bottom: 100%;
    left: 40%;
    right: -360px;
    transform: translateX(-50%);
}

.tooltip-container:hover .tooltip {
    opacity: 1;
}


@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {
    .tooltipBtn {
        background-color: #0068B9;
        color: white;
        font-size: 16px;
        /* padding: 7px 7px; */
        border: none;
        width: 170px;
    }

    .tooltip {
        left: 30%;
        right: -200px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
    .tooltipBtn {
        background-color: #0068B9;
        color: white;
        font-size: 16px;
        /* padding: 7px 7px; */
        border: none;
        width: 135px;
    }

    .tooltip {
        left: 30%;
        right: -200px;
    }

}

@media (min-width: 425px) and (max-width: 767.98px) {
    .tooltipBtn {
        background-color: #0068B9;
        color: white;
        font-size: 16px;
        /* padding: 7px 7px; */
        border: none;
        width: 375px;
    }

    .tooltip {
        left: 47%;
        right: -120px;
    }

}

@media (min-width: 375px) and (max-width: 424.98px) {
    .tooltipBtn {
        background-color: #0068B9;
        color: white;
        font-size: 16px;
        /* padding: 7px 7px; */
        border: none;
        width: 330px;
    }

    .tooltip {
        left: 47%;
        right: -120px;
    }

}

@media (min-width: 320px) and (max-width: 374.98px) {
    .tooltipBtn {
        background-color: #0068B9;
        color: white;
        font-size: 16px;
        /* padding: 7px 7px; */
        border: none;
        width: 275px;
    }

    .tooltip {
        left: 55%;
        right: -180px;
    }

}