.solutionList__Style > li {
  list-style: none;
  padding: 6px 0px;
  font-weight: 300;
}
.solutionList__Style > li::before {
  content: "\2022";
  color: #2B4470;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -2em;
}

.trainingItem__style {
  border: 1px solid #2B4470;
  padding: 6px;
  font-size: 14px;
  min-height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
