.normalBtn__style {
  border: none;
  padding: 10px 50px;
  width: 100%;
}
.activeBtn__style {
  border: none;
  padding: 10px 50px;
  background-color: var(--primary-color);
  color: white;
  /* font-size: 16px; */
  transition: all 0.2s ease-in;
  width: 100%;
}

.contactBtn__style {
  border: none;
  padding: 10px 30px;
  background-color: var(--primary-color);
  color: white;
}
