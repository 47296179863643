.contactHero__style {
  /* padding: 60px 0px; */
}

.leftCol__style {
  text-align: right;
  margin-right: 10px;
}

.mainTitle__style {
  color: black;
  font-size: 40px;
}

.titleBorder__style {
  border-bottom: 3px solid var(--secondary-color);
  width: 20%;
  border-radius: 50px;
  margin: auto;
}

.inputFiled__style {
  padding: 10px 20px;
  width: 100%;
  background-color: #ffffff96;
  border: 1px solid  rgb(156 163 175 );
  margin-top: 1.3rem;
  border-radius: 10px;
}

.inputFiled__style:focus {
  outline: none;
}

.Address__Style {
  color: var(--secondary-color);
  margin: 0px;
  padding: 0;
}

.submitBtn__style {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 20px;
  border: none;
  margin-top: 10px;
}

@media only screen and (max-width: 800px) {
  .leftCol__style>img {
    display: none;
  }

  .leftCol__style {
    text-align: left;
    margin-right: 0px;
    padding: 20px 0px;
  }

  .contactHero__style {
    padding: 10px 0px;
  }

  .mainTitle__style {
    font-size: 30px;
  }
}


@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {}

@media (min-width: 320px) and (max-width: 424.98px) {

  .mainTitle__style {
    color: var(--primary-color);
    font-size: 18px;
  }
}