.heroHeading__style {
  line-height: 2.7rem;
  color: var(--primary-color);
  font-size: 34px;
}

.titleBorder__style {
  width: 10%;
  color: black;
  border: 1px solid rgb(229, 146, 146);
  border-radius: 50px;
  margin: auto;
}

.valueRow__style {
  display: flex;
  align-items: center;
  justify-content: center;
}

.workImg__style {
  height: 150px;
  width: 150px;
  margin-bottom: 15px;
}

.summary__style {
  background-color: var(--primary-color);
  padding: 40px 0px;
  display: flex;
  align-items: center;
  margin: 30px 0px;
  margin-bottom: 60px;
}

.summary__style>img {
  width: 100px;
  height: 200px;
}

.bigWord__style {
  font-size: 60px;
  margin-right: 4px;
  color: white;
  font-weight: bold;
}

.mainDes__style {
  font-size: 24px;
  color: white;
  font-weight: lighter !important;
  line-height: 47px;
}

.talkBtn__style {
  color: var(--secondary-color);
  background-color: white;
  padding: 8px 20px;
  border: none;
}

.storyDes__style {
  line-height: 2.4rem;
  text-align: justify;
  font-weight: 400;
}

@media only screen and (max-width: 600px) {
  .summary__style>img {
    display: none;
  }

  .summary__style {
    display: block;
  }

  .workImg__style {
    height: 100px;
    width: 100px;
    margin-bottom: 10px;
  }

  .mainDes__style {
    font-size: 18px;
    color: white;
    font-weight: lighter !important;
    line-height: 47px;
  }
}

@media screen and (max-width: 780px) {
  .heroImg__style {
    display: none;
  }
}



@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {
  .heroHeading__style {
    line-height: 28px;
    color: var(--primary-color);
    font-size: 20px;
  }

}

@media (min-width: 320px) and (max-width: 424.98px) {
  .heroHeading__style {
    line-height: 28px;
    color: var(--primary-color);
    font-size: 22.6px;
  }

  .storyDes__style {
    line-height: 22px;
    text-align: justify;
    font-weight: 300;
  }

  .bigWord__style {
    font-size: 30px;
    margin-right: 0;
  }

  .mainDes__style {
    font-size: 17px;
    color: white;
    /* font-weight: lighter !important; */
    line-height: 25px;
  }

  .talkBtn__style {
    color: var(--secondary-color);
    background-color: white;
    padding: 8px 20px;
    border: none;
  }

  .summary__style {
    background-color: var(--primary-color);
    padding: 10px 0px;
    display: flex;
    align-items: center;
    margin: 30px 0px;
    margin-bottom: 60px;
  }

  .talkBtn__style {
    padding: 8px 10px;
  }
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.heroImg__style {
  animation: rotate360 1s ease-in-out;
  transition: transform 0.6s ease-in-out;
}

/* .heroImg__style:hover {
  transform: rotate(360deg);
} */



/* ServiceCard.module.css */
.text-blue {
  color: #4f6da5;
}
.text-blue2 {
  color: #1C3665;
}

.text-red {
  color: #f24548;
}
