.slider__style {
  position: relative;
}

.sliderImg__Style {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.imageOverlay__style {
  width: 100%;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.753);
  position: absolute;
  top: 0%;
  left: 0%;
}

.sliderContent__style {
  position: absolute;
  color: white;
  top: 20%;
  left: 20%;
  width: 30%;
  letter-spacing: 2px;
}

.sliderContent__style>h1 {
  font-size: 60px;
}

.sliderBtn__style {
  background-color: var(--primary--color);
  color: white;
  padding: 7px 20px;
  border: 1px solid white;
  margin-top: 10px;
}

.sliderDescription__style {
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .sliderImg__Style {
    height: 350px;
  }

  .sliderContent__style {
    top: 10%;
    left: 8%;
    width: 90%;
  }

  .sliderContent__style>h1 {
    font-size: 45px;
  }
}



@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {

  .sliderBtn__style {
    padding: 5px 16px;
    border: 1px solid white;
    margin-top: 3px;
  }

}

@media (min-width: 320px) and (max-width: 424.98px) {
  .sliderBtn__style {
    padding: 5px 16px;
    border: 1px solid white;
    margin-top: 0px;
    margin-bottom: 10px;
  }
}