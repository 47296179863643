.summary2__style {
  background-color: var(--primary-color);
  padding: 18px 0px 12px 0px;
  display: flex;
  align-items: center;
  margin: 50px 0px;
}

.summary2__style>img {
  width: 100px;
  height: 200px;
}

.summary3__style {
  background-color: #ebf3fabf;
  /* padding: 18px 0px 12px 0px; */
  display: flex;
  align-items: center;
  margin: 0px;
}

.summary3__style>img {
  width: 100px;
  height: 200px;
}

.bigWord__style {
  font-size: 60px;
  margin-right: 4px;
  color: white;
  font-weight: bold;
}

.mainDes__style {
  font-size: 24px;
  color: white;
  font-weight: lighter !important;
  line-height: 47px;
  text-align: justify;
}

.contactBtn__style {
  padding: 8px 30px;
  border-radius: 3px;
  color: var(--secondary-color);
  border: none;
  margin-top: 10px;
}


.normalBtn__style {
  /* border: none; */
  padding: 10px 50px;
  width: 100%;
  background-color: #f5f5f5;
  color: #1B3665;
}

.activeBtn__style {
  padding: 10px 50px;
  background-color: #f9f9f9bf;
  color: #EA2126;
  /* font-size: 16px; */
  transition: all 0.2s ease-in;
  width: 100%;
}

.contactBtn__style {
  border: none;
  padding: 10px 30px;
  background-color: var(--primary-color);
  color: white;
}


@media only screen and (max-width: 600px) {
  .summary__style>img {
    display: none;
  }

  .summary__style {
    display: block;
  }

  .mainDes__style {
    font-size: 18px;
    color: white;
    font-weight: lighter !important;
    line-height: 47px;
  }
}