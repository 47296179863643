.campainCard {
    position: relative;
    width: 350px;
    height: 221px;
    background-size: cover;
    border: 2px solid gray;
    padding: 20px;
    border-radius: 6px;
    transition: background-color 0.9s ease;
}

.card1 {
    background-image: url("../../../../assets/Images/server_security/cyberSecurityAwarness01.webp");
}

.card2 {
    background-image: url("../../../../assets/Images/server_security/cyberSecurityAwarness02.jpg");
}

.card3 {
    background-image: url("../../../../assets/Images/server_security/cyberSecurityAwarness03.jpg");
}

.campainCard::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    transition: background-color 0.9s ease;
}

.cardTitle {
    position: relative;
    text-align: center;
    margin: 12% 0;
    color: white;
    z-index: 1;
}


.campainCard:hover::before {
    background-color: rgba(0, 0, 0, 0.45);
}

.campainCard:hover {
    background-color: rgb(204, 205, 207);
    box-shadow: 0px 0px 18px rgb(36, 165, 216);
}



@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {
    .campainCard {
        width: 270px;
        height: 196px;
    }

    .cardTitle {
        font-size: 25px;
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
    .campainCard {
        width: 640px;
        height: 306px;
        padding: 15px;
        margin-bottom: 5px;
    }

    .cardTitle {
        font-size: 45px;
    }
}

@media (min-width: 425px) and (max-width: 767.98px) {
    .campainCard {
        width: 320px;
        height: 170px;
        padding: 7px;
        margin-bottom: 5px;
    }
}

@media (min-width: 375px) and (max-width: 424.98px) {
    .campainCard {
        width: 300px;
        height: 166px;
        padding: 7px;
        margin-bottom: 5px;
    }
}

@media (min-width: 320px) and (max-width: 374.98px) {
    .campainCard {
        width: 250px;
        height: 160px;
        padding: 7px;
        margin-bottom: 5px;
    }
}