

.x-scrollbar {
    height: 300px; /* Set desired height */
    overflow-y: auto; /* Enable vertical scrollbar */
  }
  
  .x-scrollbar::-webkit-scrollbar {
    width: 8px; /* Set scrollbar width */
  }
  
  .x-scrollbar::-webkit-scrollbar-track {
    background: #f1f1f1; /* Set track color */
  }
  
  .x-scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* Set thumb color */
    border-radius: 10px; /* Set thumb border radius */
  }
  
  .x-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* Set thumb color on hover */
  }
  
  .x-scrollbar .content {
    padding: 20px; /* Add padding to the content */
  }
  
  .x-scrollbar .content p {
    margin: 0; /* Remove default margin for paragraphs */
    line-height: 1.5; /* Set line height */
    color: #333; /* Set text color */
  }
  
  .x-scrollbar .content p:last-child {
    margin-bottom: 0; /* Remove margin for the last paragraph */
  }
  