.socialLink__Style {
  font-size: 28px;
  /* display: flex; */
  cursor: pointer;
  color: var(--primary-color);
}

.heading__style {
  color: var(--primary-color);
}

.headingBorder__style {
  border: 2px solid var(--primary-color);
  border-radius: 40px;
  width: 20%;
  margin: auto;
}

.navigateLink__style {
  text-decoration: none;
  color: var(--primary-color);
  padding: 10px 0px;
}

.footerBottom__style {
  margin-top: 40px;
  font-size: 14px;
}

.rightCol__Style {
  padding-left: 80px !important;
}

.middleCol__style {
  padding-left: 80px !important;
}

@media screen and (max-width: 800px) {
  .rightCol__Style {
    padding-left: 10px !important;
  }

  .middleCol__style {
    padding-left: 10px !important;
  }
}

.footerLogo {}


@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
  .footerLogo {
    width: 150px;
  }
}

@media (min-width: 425px) and (max-width: 767.98px) {
  /* .footerLogo {
    width: 150px;
  } */
}

@media (min-width: 320px) and (max-width: 424.98px) {

  .footerBottom__style {
    margin-top: 40px;
    font-size: 11px;
    margin: 15px 20px -6px;
  }
}