.circle-trail {
    position: fixed;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    background-color: rgba(135, 206, 235, 0.8); /* Light blue color */
    border-radius: 50%;
    pointer-events: none; /* Make sure the circle doesn't interfere with mouse events */
    z-index: 1000; /* Ensure it's above other elements */
    transition: transform 0.1s ease-out, opacity 0.3s ease-out;
  }
  
  .circle-trail.vanish {
    opacity: 0;
  }
  