  .mainNav__style {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 17px 35px;
    background-color: white;
    box-shadow: 0 0 10px rgb(206, 201, 201);
  }
  .navWidth {
    max-width: 1280px/* 1280px */;
}

.rightNav__style {}

.activeNav__style {
  color: var(--secondary-color);
  text-decoration: none;
  /* padding: 0px 10px; */
  padding: 0px 8px;
  margin-right: 20px;
}

.activeNav__style:hover {
  color: var(--secondary-color);
  text-decoration: none;
  /* padding: 0px 10px; */
  padding: 0px 8px;
}

.normalNav__style {
  color: var(--primary-color);
  text-decoration: none;
  /* padding: 0px 10px; */
  padding: 0px 8px;
  margin-right: 20px;
}

.normalNav__style:hover {
  color: red;
  text-decoration: none;
  /* padding: 0px 10px; */
  padding: 0px 8px;
}

.contactIcon__style {
  padding-left: 8px;
}

.mobileNav__style {
  display: none;
}

@media screen and (max-width: 1350px) {
  .rightNav__style {
    display: none;
  }

  .mobileNav__style {
    display: block;
  }

  .mainNav__style {
    padding: 15px 0px;
    padding-left: 220px;
  }
}

@media screen and (max-width: 1349px) {
  .mainNav__style {
    padding: 15px 0px;
    padding-left: 30px;
  }
}

/* @media screen and (max-width: 600px) {
  .mainNav__style {
    padding: 15px 0px;
    padding-left: 30px;
  }
} */
