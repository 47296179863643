.awarnessDisplay {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.star {
    color: black;
    font-size: 90%;
    margin-left: 4px;
    display: flex;
}

.regDetailLink {
    font-size: 15px;
}

.regForm {
    background-color: #F0F6FB;
}

.regDetailsBody {
    background-color: #F0F6FB;
}

@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (min-width: 992px) and (max-width: 1023.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 425px) and (max-width: 767.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 375px) and (max-width: 424.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (min-width: 320px) and (max-width: 374.98px) {
    .awarnessDisplay {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
}