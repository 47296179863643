.textTech {
  font-weight: 600;
  /* background-color: white; */
  color: #1C3664;
}

.textSimple {
  font-weight: 600;
  /* background-color: white; */
  color: #EA2126
}

.textICT {
  font-weight: 600;
  /* padding: 0 5px; */
  /* background-color: white; */
  color: #1C3664;
}

.glowText {

  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(179, 9, 3, 0.959);
    /* Adjust the glow color and size as needed */
  }

  50% {
    text-shadow: 0 0 20px rgb(5, 12, 149), 0 0 30px rgba(5, 171, 69, 0.8);
    /* You can add more keyframes for a more complex glow effect */
  }

  100% {
    text-shadow: 0 0 20px rgb(204, 236, 25), 0 0 30px rgba(255, 0, 183, 0.8);
    /* You can add more keyframes for a more complex glow effect */
  }
}



.highlight {
  background-color: yellow;
  /* Adjust the background color as needed */
  color: black;
  /* Adjust the text color as needed */
}

.solutionList>li {
  list-style: none;
  padding: 6px 0px;
  font-weight: 300;
}

.solutionList>li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -2em;
}

.trainingItem {
  border: 1px solid var(--secondary-color);
  padding: 6px;
  font-size: 14px;
  min-height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.awarnessCapmainArea {
  /* background-color: rgb(245, 246, 248); */
}