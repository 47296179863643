
.parallax-effect-glare-scale {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  background-color: darkgreen;
  color: white;
  border: 5px solid black;
  border-radius: 20px;

  transform-style: preserve-3d;

  .inner-element {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    font-style: italic;
    color: white;
    transform: translateZ(60px);
  }
}

.serviceImg__style {
  width: 100%;
  height: 100px;
  object-fit: contain;
  margin: 20px 0px;
}

.serialNum__style {
  color: var(--secondary-color);
}


/* ServiceCard.module.css */
.text-blue {
  color: #4f6da5;
}
.text-blue2 {
  color: #1C3665;
}

.text-red {
  color: #EB3539;
}
