.summary__style {
  background-color: #0e0e0e;
  padding: 40px 0px;
  display: flex;
  align-items: center;
  margin: 50px 0px;
  padding-bottom: 50px;
}

.summary__style > img {
  width: 100px;
  height: 200px;
}

.bigWord__style {
  font-size: 60px;
  margin-right: 4px;
  color: white;
  font-weight: bold;
}

.mainDes__style {
  font-size: 24px;
  color: white;
  font-weight: lighter !important;
  line-height: 47px;
  text-align: justify;
}

@media only screen and (max-width: 600px) {
  .summary__style > img {
    display: none;
  }

  .summary__style {
    display: block;
  }
  .mainDes__style {
    font-size: 18px;
    color: white;
    font-weight: lighter !important;
    line-height: 47px;
  }
}
