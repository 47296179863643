/* ServiceSection.css */

.image-indicator {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  
}

.dot {
  height: 10px;
  width: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #bbb;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color: #2b6cb0;
}

/* ServiceSection.css */

.image-slide.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px; /* Adjust height to match image container */
  background-color: #f0f0f0; /* Placeholder background color */
}

.image-slide.loading img {
  display: none; /* Hide image while loading */
}

.image-slide.loading::after {
  content: "Loading..."; /* Display loading text or spinner */
  font-size: 16px;
  color: #333;
}

