.slider {
    position: relative;
}

.sliderImg {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.imageOverlay {
    width: 100%;
    height: 400px;
    /* background-color: rgba(0, 0, 0, 0.753); */
    background-color: rgba(0, 0, 0, 0.400);
    position: absolute;
    top: 0%;
    left: 0%;
}

.sliderContent {
    position: absolute;
    color: white;
    top: 20%;
    left: 10%;
    width: 50%;
    letter-spacing: 1px;
}

.bannerHeading {}

.cisaLogo {
    position: absolute;
    background-color: white;
    width: 350px;
    padding: 15px;
    border-radius: 15px;
    top: 30%;
    right: 10%;
}

.sliderContent>h1 {
    font-size: 60px;
}

.sliderBtn {
    background-color: var(--primary--color);
    color: white;
    padding: 7px 20px;
    border: 1px solid white;
    margin-top: 10px;
}

.sliderBtnBottom {
    background-color: var(--primary--color);
    color: black;
    padding: 7px 20px;
    border: 2px solid rgb(0, 0, 0);
    margin-top: 0px;
}

.sliderBtnBottom:hover {
    background-color: rgb(227, 229, 231);

}

.sliderDescription {
    font-size: 20px;
}

@media screen and (max-width: 600px) {
    .sliderImg {
        height: 350px;
    }

    .sliderContent {
        top: 10%;
        left: 8%;
        width: 90%;
    }

    .sliderContent>h1 {
        font-size: 45px;
    }
}




@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {
    .bannerHeading {
        font-size: 53px !important;
    }

    .cisaLogo {
        width: 300px;
        padding: 8px;
        border-radius: 15px;
        top: 34%;
        right: 8%;
    }

}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {
    .cisaLogo {
        position: absolute;
        background-color: white;
        width: 220px;
        padding: 4px;
        border-radius: 15px;
        top: 39%;
        right: 8%;
    }

    .bannerHeading {
        font-size: 35px !important;
        letter-spacing: -1px !important;
    }
}

@media (min-width: 425px) and (max-width: 767.98px) {
    .imageOverlay {
        width: 100%;
        height: 350px;
        background-color: rgba(0, 0, 0, 0.400);
        position: absolute;
        top: 0%;
        left: 0%;
    }

    .cisaLogo {
        position: absolute;
        background-color: white;
        width: 120px;
        padding: 3px;
        border-radius: 15px;
        top: 80%;
        right: 8%;
    }

    .bannerHeading {
        margin-top: 40px;
        text-align: center;
        font-size: 35px !important;
        letter-spacing: -1px !important;
    }

    .sliderDescription {
        font-size: 18px !important;
    }

}

@media (min-width: 375px) and (max-width: 424.98px) {

    .imageOverlay {
        width: 100%;
        height: 350px;
        background-color: rgba(0, 0, 0, 0.400);
        position: absolute;
        top: 0%;
        left: 0%;
    }

    .cisaLogo {
        width: 115px;
        padding: 3px;
        border-radius: 15px;
        top: 80%;
        right: 8%;
    }

    .bannerHeading {
        margin-top: 35px;
        font-size: 35px !important;
        letter-spacing: -1px !important;
    }

    .sliderDescription {
        font-size: 18px !important;
    }

}

@media (min-width: 320px) and (max-width: 374.98px) {

    .imageOverlay {
        width: 100%;
        height: 350px;
        background-color: rgba(0, 0, 0, 0.400);
        position: absolute;
        top: 0%;
        left: 0%;
    }

    .cisaLogo {
        width: 93px;
        padding: 3px;
        border-radius: 15px;
        top: 80%;
        right: 8%;
    }

    .bannerHeading {
        margin-top: 15px;
        font-size: 32px !important;
        letter-spacing: 0px !important;
    }

    .sliderDescription {
        font-size: 18px !important;
    }

}