.title__Style {
  color: var(--primary-color);
  text-align: center;
}

.titleBorder__style {
  width: 10%;
  margin: auto;
  border-bottom: 3px solid var(--secondary-color);
  border-radius: 20%;
}
