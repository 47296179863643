.mainDiv__style {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.childDiv__style {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 10vh;
  flex-direction: column;
}

.notFoundImg__style {
  width: 300px;
  height: 400px;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .childDiv__style {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    height: 10vh;
    flex-direction: column;
  }
}
