.activeNav__style {
  text-decoration: none;
  width: 100%;
  color: white;
  background-color: var(--primary-color);
  /* border-radius: 100px 0px 100px 0px; */
  padding: 10px 20px;
  text-align: center;
}
.activeNav__style:hover {
  text-decoration: none;
  color: white;
}

.normalNav__style {
  text-decoration: none;
  width: 100%;
  color: var(--primary-color);

  text-align: center;
}

.normalNav__style:hover {
  text-decoration: none;
  color: var(--primary-color);
}
