@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@300;400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lexend", sans-serif;
}

:root {
  --primary-color: #1c3664;
  --secondary-color: #eb3539;
}

p,
small {
  font-weight: 400;
}

/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--secondary-color);
  opacity: 1;
}

/* target all bullets */
.swiper-pagination-bullet {
  background-color: red;
  opacity: 1;
}

.swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.637);
}

.commonBtn__style {
  background-color: var(--primary-color);
  color: white;
  padding: 8px 20px;
  border: none;
}


/*
@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {}

@media (min-width: 320px) and (max-width: 424.98px) {} 
*/


.custom-scrollbar {
  height: 300px; /* Set desired height */
  overflow-y: auto; /* Enable vertical scrollbar */
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Set scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1; /* Set track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888; /* Set thumb color */
  border-radius: 10px; /* Set thumb border radius */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555; /* Set thumb color on hover */
}

.custom-scrollbar .content {
  padding: 20px; /* Add padding to the content */
}

.custom-scrollbar .content p {
  margin: 0; /* Remove default margin for paragraphs */
  line-height: 1.5; /* Set line height */
  color: #333; /* Set text color */
}

.custom-scrollbar .content p:last-child {
  margin-bottom: 0; /* Remove margin for the last paragraph */
}


.red-asterisk::before {
  content: "*";
  color: #AC154B;
  margin-right: 0.5em; /* Adjust this value to your preference */
}



.tech{
color: #1C3766;
}
.simple{
color: #E92125;
}

.solution{
  color: #75BE39;
}



/* global.css or index.css */
html {
  scrollbar-width: thin;
  scrollbar-color: #2c55a1 #E5E5E5;
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
  
}

::-webkit-scrollbar-track {
  background: #E5E5E5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #3568c5;
  border-radius: 10px;
  border: 3px solid #E5E5E5;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #63a3e3;
}


/* fb messenger */
.fb-customerchat {
  position: fixed !important;
  bottom: 0 !important;
  right: 0 !important;
}
