.commonHeader {}

.headerUnderline__style {
  width: 10%;
  margin: auto;
  border: 2px solid red;
  background-color: red;
  color: red;
}

.mainHeading__stye {
  text-align: center;
  margin-top: -30px;
  font-size: 48px;
}

.shadowHeading__stye {
  color: var(--secondary-color);
  text-align: center;
  opacity: 0.1;
  font-size: 70px;
  padding: 0px;
  margin: 0;
  text-transform: uppercase;
}

.headingBorder__style {
  width: 10%;
  margin: auto;
  border-bottom: 3px solid var(--secondary-color);
  border-radius: 20%;
}

@media screen and (max-width: 800px) {
  .shadowHeading__stye {
    font-size: 60px;
  }

  .mainHeading__stye {
    font-size: 40px;
  }
}




@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {
  .shadowHeading__stye {
    font-size: 45px;
  }
}

@media (min-width: 375px) and (max-width: 424.98px) {
  .shadowHeading__stye {
    font-size: 42px;
  }
}

@media (min-width: 320px) and (max-width: 374.98px) {
  .shadowHeading__stye {
    font-size: 37px;
  }
}