.solutionList__Style > li {
  list-style: none;
  padding: 6px 0px;
  font-weight: 300;
}
.solutionList__Style > li::before {
  content: "\2022";
  color: red;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -2em;
}
