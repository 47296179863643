.courseDetailsBanner {
    background-color: #F0F6FB;
}

.trainerImg {
    width: 170px;
    height: 190px;
    border-radius: 50%;
}

.courseDescription {
    /* text-align: justify; */
}

.trainingSubHead {
    font-size: 20px;
}



@media (min-width: 1440px) and (max-width: 2559.98px) {}

@media (min-width: 1024px) and (max-width: 1199.98px) {}

@media (min-width: 992px) and (max-width: 1023.98px) {}

@media (min-width: 768px) and (max-width: 991.98px) {}

@media (min-width: 425px) and (max-width: 767.98px) {
    .trainingSubHead {
        font-size: 20px;
    }

    .courseDetailsBanner {
        font-size: 20px;
    }
}

@media (min-width: 320px) and (max-width: 424.98px) {
    .trainingSubHead {
        font-size: 20px;
    }

    .courseDetailsBanner {
        font-size: 19px;
    }
}